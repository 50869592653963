import React from 'react'
import PropTypes from 'prop-types'

import { SelectMain, SelectElement } from './index.style'
import SelectArrow from '@svgs/SelectArrow'

const Select = ({ children, onChange }) => {
  return (
    <SelectMain onChange={onChange}>
      <SelectArrow />
      <SelectElement>{children}</SelectElement>
    </SelectMain>
  )
}

Select.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
}

export default Select
