import { clamp } from '@styles/utils/conversion.style'
import { easings } from '@styles/vars/easings.style'
import { mq } from '@styles/vars/media-queries.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import styled from 'styled-components'

export const MapMain = styled.div`
  width: 100%;
`

export const MapInner = styled.div`
  position: relative;
`

export const MapImageWrap = styled.div`
  position: relative;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s ${easings.out.cubic};

  ${mq.desk} {
    ${clamp('height', 495, 660, breakpoints.desk, breakpoints.contained)};
  }
`

export const MapImage = styled.div`
  ${mq.desk} {
    align-items: center;
    display: flex;
    height: 100%;
    left: 0;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;

    img {
      max-height: 100%;
      height: auto;
      max-width: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) !important;
      width: auto;
    }
  }
`
