import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const ContentMain = styled.div`
  height: 100%;
  padding-bottom: 17rem;
  position: relative;

  ${mq.desk} {
    padding-bottom: 0;
  }
`

export const ContentSection = styled.div`
  display: ${props => (props.active ? `block` : `none`)};

  ${mq.desk} {
    display: ${props => (props.fillHeight ? 'grid' : 'block')};
    ${({ fillHeight }) => {
      if (fillHeight) {
        return `
          place-items: center;
        `
      }
    }}
    height: ${props => (props.active ? `100%` : 0)};
    left: 0;
    opacity: ${props => (props.active ? 1 : 0)};
    overflow: ${props =>
      props.active && !props.fixHeight ? `auto` : `hidden`};
    pointer-events: ${props => (props.active ? `auto` : `none`)};
    position: absolute;
    right: 0;
    top: 0;
  }
`
