import styled from 'styled-components'
import { font } from '@styles/vars/font.style'

export const SelectMain = styled.div`
  display: block;
  position: relative;

  > svg {
    position: absolute;
    right: 2.4rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
`

export const SelectElement = styled.select`
  appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 2.5rem;
  color: inherit;
  font-family: ${font.secondary.family};
  font-weight: ${font.secondary.weight.medium};
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  line-height: 1;
  padding: 1.7rem 2.4rem 1.7rem;
  position: relative;
  text-transform: uppercase;
  width: 100%;
  z-index: 1;
`
