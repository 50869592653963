import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { clamp } from '@styles/utils/conversion.style'
import styled, { css } from 'styled-components'
import {
  paddingMin,
  paddingMax,
  matchContainerPadding,
} from '@components/Container/index.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { CaptionSmall, TextBodySmall } from '@styles/vars/textStyles.style'
import { easings } from '@styles/vars/easings.style'

export const SidebarMain = styled.div`
  position: relative;
  text-align: center;

  ${mq.desk} {
    height: 100%;
    overflow: auto;
    text-align: left;
  }

  &:before,
  &:after {
    background-color: ${colors.darkblue};
    content: '';
    height: 0.1rem;
    left: 0;
    ${clamp(
      'left',
      -paddingMin,
      -paddingMax,
      breakpoints.mobile,
      breakpoints.tabletL
    )};
    opacity: 0.12;
    position: absolute;
    ${clamp(
      'right',
      -paddingMin,
      -paddingMax,
      breakpoints.mobile,
      breakpoints.tabletL
    )};
    top: 0;

    ${mq.desk} {
      left: auto;
      right: 0;
      height: 100%;
      width: 0.1rem;
    }
  }

  &:after {
    bottom: 0;
    top: auto;

    ${mq.desk} {
      display: none;
    }
  }

  ${CaptionSmall} {
    ${mq.desk} {
      border: solid 0.1rem currentColor;
      border-radius: 1.5rem;
      display: inline-block;
      padding: 0.5rem 1rem 0.4rem;
    }
  }
`

const sidebarPadding = css`
  ${mq.desk} {
    padding-right: 5rem;
    ${matchContainerPadding('padding-left')}
  }

  ${mq.deskM} {
    ${clamp('padding-right', 20, 50, breakpoints.deskM, breakpoints.contained)};
  }

  ${mq.max} {
    padding-left: calc(50vw - ${breakpoints.contained / 2}px);
  }
`

export const SidebarTop = styled.div`
  ${sidebarPadding}

  ${mq.desk} {
    position: relative;
    min-height: 50%;
    padding-bottom: 4rem;

    ::before {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      width: 50vw;
      height: 0.1rem;
      opacity: 0.12;
      background-color: ${colors.darkblue};
    }
  }
`

export const SidebarIntro = styled.div`
  ${mq.deskMax} {
    ${TextBodySmall} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const SidebarSelect = styled.div`
  padding-bottom: 4rem;
  padding-top: 3.2rem;

  ${mq.desk} {
    display: none;
  }
`

export const SidebarMenu = styled.ul`
  display: none;
  list-style-type: none;
  padding-bottom: 4rem;
  padding-top: 4.6rem;
  ${sidebarPadding}

  ${mq.desk} {
    display: block;
  }
`

export const SidebarMenuLink = styled.a`
  cursor: pointer;
  text-decoration: none;
`

export const SidebarMenuItem = styled.li`
  margin-bottom: 1.8rem;
  position: relative;
  transition: transform 0.4s ${easings.out.cubic};

  ${SidebarMenuLink} {
    position: relative;

    &:before {
      background-color: ${colors.pink2};
      border-radius: 50%;
      content: '';
      height: 0.5rem;
      left: -0.7rem;
      opacity: ${props => (props.active ? 1 : 0)};
      position: absolute;
      top: 50%;
      transition: opacity 0.4s ${easings.out.cubic};
      transform: translateY(-50%) translateX(-100%);
      width: 0.5rem;
    }
  }
`
