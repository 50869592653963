import { ButtonMain } from '@components/Button/index.style'
import { colors } from '@styles/vars/colors.style'
import { easings } from '@styles/vars/easings.style'
import { mq } from '@styles/vars/media-queries.style'
import styled, { css } from 'styled-components'
import SoundWaveMobile from './images/soundwave-mobile.png'
import SoundWave from './images/soundwave.png'

export const audioPlayerHeight = 113

export const AudioPlayerMain = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 50;
`

export const AudioPlayerWrap = styled.div`
  background-color: ${colors.pink2};
  color: ${colors.light};
  padding-bottom: 2.4rem;
  padding-top: 1.8rem;
  transform: translateY(${props => (props.visible ? 0 : 100)}%);
  transition: transform 1s ${easings.out.cubic};

  ${mq.desk} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${audioPlayerHeight / 10}rem;
    padding-bottom: 0;
    padding-top: 0;

    @media (max-height: 880px) {
      height: 7rem;
    }
  }
`

export const AudioPlayerButton = styled.div`
  text-align: center;

  ${ButtonMain} {
    height: 4.4rem;
    width: 4.4rem;

    svg {
      height: 1.2rem;
      width: 1rem;
    }
  }

  ${mq.desk} {
    margin-right: 5rem;
    order: -1;
  }
`

export const AudioPlayerInner = styled.div`
  ${mq.desk} {
    display: flex;
  }
`

export const AudioPlayerLineWrap = styled.div`
  background-image: url(${SoundWaveMobile});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 1.3rem 0;
  margin-bottom: 1.4rem;
  position: relative;
  width: 100%;

  ${mq.tabletP} {
    background-image: url(${SoundWave});
  }

  ${mq.desk} {
    align-self: center;
    margin: 0 5rem;
  }
`

export const AudioPlayerLine = styled.div`
  background-color: ${colors.pink3};
  border-radius: 0.2rem;
  height: 0.4rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
`

export const AudioPlayerLineProgress = styled.div.attrs(props => ({
  style: {
    transform: `scaleX(${props.progress})`,
  },
}))`
  background-color: ${colors.offWhite};
  height: 100%;
  transform-origin: 0% 0%;
  width: 100%;
`

export const AudioPlayerLineDisc = styled.div.attrs(props => ({
  style: {
    left: `${props.progress * 100}%`,
  },
}))`
  background-color: ${colors.light};
  border-radius: 50%;
  position: absolute;
  height: 0.8rem;
  margin-left: -0.4rem;
  margin-top: -0.4rem;
  top: 50%;
  width: 0.8rem;
  z-index: 3;
`

const Numbers = css`
  bottom: -3.6rem;
  position: absolute;
  transform: translateY(50%);

  ${mq.desk} {
    bottom: auto;
    top: 50%;
  }
`

export const AudioPlayerProgress = styled.div`
  ${Numbers};
  left: 0;

  ${mq.desk} {
    left: -2.4rem;
    transform: translateY(-50%) translateX(-100%);
  }
`

export const AudioPlayerDuration = styled.div`
  ${Numbers};
  right: 0;

  ${mq.desk} {
    right: -2.4rem;
    transform: translateY(-50%) translateX(100%);
  }
`
