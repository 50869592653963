import React from 'react'

const Close = ({
  width = 17,
  height = 17,
  stroke = 'currentColor',
  responsive = true,
  open = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M15.527 1 1 15.527M1 1l14.527 14.527"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  )
}

export default Close
