import { ButtonMain } from '@components/Button/index.style'
import GridItem from '@components/GridItem'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { colors } from '@styles/vars/colors.style'
import { easings } from '@styles/vars/easings.style'
import { mq } from '@styles/vars/media-queries.style'
import { transparentize } from 'polished'
import styled from 'styled-components'

export const CarouselMain = styled.div``

export const CarouselInner = styled.div`
  position: relative;
`

export const CarouselImages = styled.div`
  ${clamp('height', 240, 660)};
  position: relative;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 3)}rem);
  transition: transform 1.5s ${easings.out.cubic},
    opacity 1.5s ${easings.out.cubic};
  perspective: 400px;
  pointer-events: none;
  user-select: none;

  ${mq.desk} {
    ${clamp('height', 440, 660, breakpoints.desk)};

    @media (max-height: 880px) {
      height: 40rem;
    }
  }

  ${mq.deskM} {
    ${clamp('height', 440, 700, breakpoints.deskM)};

    @media (max-height: 880px) {
      height: 40rem;
    }
  }
`

export const CarouselImage = styled.div`
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotateY(${props => props.zPos * 0.5}deg)
    translateZ(${props => props.zPos * 50}px);
  transform-origin: 50% 75%;
  transition: transform 1s ${easings.out.cubic};
  width: 100%;
  will-change: transform;
  z-index: ${props => props.zIndex};

  ${mq.desk} {
    transform: rotateX(${props => props.zPos * -1}deg)
      translateZ(${props => props.zPos * 125}px)
      rotateX(${props => props.zPos * -4}deg);
  }

  .gatsby-image-wrapper {
    height: 100%;
    opacity: ${props => (props.current ? 1 : 0)};
    transition: opacity ${props => (props.current ? 0.5 : 0.2)}s
      ${easings.out.cubic} ${props => (props.active ? 0.2 : 0)}s;
    width: 100%;
  }

  img {
    border-radius: 1rem;
    max-height: 100%;
    height: auto;
    max-width: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) !important;
    width: auto;
  }
`

export const CarouselDetailGridItem = styled(GridItem)`
  ${mq.deskMax} {
    backface-visibility: visible;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    will-change: opacity;
    z-index: 10;
  }
`

export const CarouselDetails = styled.div`
  display: grid;
  grid-template: 1fr / 1fr;
  ${clamp('height', 240, 660)};
  place-items: start;
  justify-content: end;
  align-items: center;
  background-color: ${colors.dark};
  bottom: 0;
  color: ${colors.light};
  opacity: ${props => (props.mobileActive ? 1 : 0)};
  left: 0;
  pointer-events: ${props => (props.mobileActive ? `auto` : `none`)};
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 1s ${easings.out.cubic},
    visibility ${props => (props.mobileActive ? `0s 0s` : `0s 1s`)};
  visibility: ${props => (props.mobileActive ? `visible` : `hidden`)};
  z-index: 10;

  ${mq.desk} {
    align-items: end;
    border-radius: 1rem;
    height: auto;
    max-width: 25rem;
    background-color: transparent;
    border-radius: 0;
    color: ${colors.dark};
    opacity: 1;
    position: relative;
    visibility: visible;
  }
`

export const CarouselDetail = styled.div`
  display: flex;
  grid-area: 1 / 1 / auto / auto;
  flex-direction: column;
  justify-content: center;
  opacity: ${props => (props.active ? 1 : 0)};
  padding: 2.4rem;
  pointer-events: ${props => (props.active ? `auto` : `none`)};
  visibility: ${props => (props.active ? `visible` : `hidden`)};

  ${mq.desk} {
    padding: 0;
  }
`

export const CarouselControls = styled.div`
  align-items: center;
  display: none;
  padding-top: 4.5rem;
  user-select: none;

  ${mq.desk} {
    display: flex;
  }
`

export const CarouselCounter = styled.div`
  width: 7.5rem;

  p {
    text-align: center;
  }
`

export const CarouselPagination = styled.div`
  position: relative;

  ${mq.desk} {
    display: none;
  }
`

export const CarouselDots = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
`

export const CarouselDot = styled.li`
  border: solid 0.1rem ${colors.dark};
  border-radius: 50%;
  height: 0.6rem;
  opacity: ${props => (props.active ? 1 : 0.3)};
  margin: 0 0.4rem;
  position: relative;
  width: 0.6rem;

  &:before {
    background-color: ${colors.dark};
    border: solid 0.1rem ${colors.dark};
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    opacity: ${props => (props.active ? 1 : 0)};
    position: absolute;
    top: 0;
    transform: translateY(${props => (props.active ? 0 : `25%`)});
    width: 100%;
  }
`

export const CarouselInfo = styled.div`
  font-size: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  ${ButtonMain} {
    background: transparent;
    border: solid ${transparentize(0.7, colors.dark)} 0.1rem;
    height: 3.2rem;
    width: 3.2rem;

    svg {
      max-width: 1rem;
    }
  }

  ${mq.desk} {
    display: none;
  }
`
