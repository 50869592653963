import { mq } from '@styles/vars/media-queries.style'
import styled, { css } from 'styled-components'
import { easings } from '@styles/vars/easings.style'
import {
  matchContainerPadding,
  paddingMax,
} from '@components/Container/index.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { audioPlayerHeight } from './AudioPlayer/index.style'

export const EpisodeMain = styled.div``

export const EpisodeSpacer = styled.div`
  position: relative;

  &:after {
    background-color: ${colors.darkblue};
    bottom: 0;
    content: '';
    display: none;
    height: 0.1rem;
    left: -${paddingMax}px;
    opacity: 0.12;
    position: absolute;
    right: -${paddingMax}px;

    ${mq.desk} {
      display: block;
    }

    ${mq.contained} {
      left: calc(-50vw + ${breakpoints.contained / 2}px - ${paddingMax}px);
      right: calc(-50vw + ${breakpoints.contained / 2}px - ${paddingMax}px);
    }
  }
`

export const EpisodeInner = styled.div`
  ${mq.desk} {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`

export const EpisodeContent = styled.div`
  ${mq.desk} {
    display: flex;
    flex: 1;
    overflow: hidden;

    .episode-content,
    .episode-sidebar {
    }
  }
`

const columnStyles = css`
  ${mq.deskMax} {
    height: 100%;
    ${matchContainerPadding('padding-left')}
    ${matchContainerPadding('padding-right')}
  overflow: hidden;
  }
`

export const EpisodeColLeft = styled.div`
  ${columnStyles}

  ${mq.desk} {
    width: 25.8%;
  }

  ${mq.deskM} {
    width: 17.7%;
  }

  ${mq.max} {
    width: calc(50vw - ${breakpoints.contained / 2 / 10}rem + 30rem);
  }
`

export const EpisodeColRight = styled.div`
  flex: 1;
  ${columnStyles}

  ${mq.desk} {
    ${matchContainerPadding('padding-right')}
  }

  ${mq.max} {
    padding-right: calc(50vw - ${breakpoints.contained / 2 / 10}rem);
  }
`

export const EpisodeAudioSpacer = styled.div`
  display: none;

  ${mq.desk} {
    display: block;
    height: ${audioPlayerHeight / 10}rem;

    @media (max-height: 880px) {
      height: 7rem;
    }
  }
`

export const EpisodeNext = styled.div`
  background-color: ${colors.dark};
  border-radius: 1rem;
  bottom: 13.7rem;
  box-shadow: 0px 0px 20px 15px #ecebea;
  display: none;
  ${clamp('height', 169, 212, breakpoints.desk, breakpoints.contained)};
  opacity: ${props => (props.visible ? 1 : 0)};
  overflow: hidden;
  pointer-events: ${props => (props.visible ? `auto` : `none`)};
  position: fixed;
  right: ${paddingMax}px;
  transition: opacity 1s ${easings.out.cubic};
  transition-delay: ${props => (props.visible ? 1 : 0)}s;
  ${clamp('width', 246, 308, breakpoints.desk, breakpoints.contained)};

  ${mq.desk} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const EpisodeNextClose = styled.div`
  color: ${colors.light};
  cursor: pointer;
  height: 1.4rem;
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  width: 1.4rem;
  z-index: 2;
`

export const EpisodeNextBackground = styled.div`
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
`
