import RichText from '@components/RichText'
import Spacer from '@components/Spacer'
import React from 'react'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { ContentMain, ContentSection } from './index.style'
import Carousel from '@components/Episode/Carousel'
import Map from '@components/Episode/Map'
import { useEffect } from 'react'
import { useRef } from 'react'

const Content = ({ index, carouselItems, audioTranscript, map }) => {
  const $blocks = useRef([])

  useEffect(() => {
    $blocks.current.forEach($block => {
      $block.scrollTop = 0
    })
  }, [index])

  return (
    <ContentMain>
      {carouselItems && (
        <ContentSection
          ref={ref => ($blocks.current[0] = ref)}
          active={index === 0}
          fillHeight
          fixHeight
        >
          <Spacer size={24} />

          <Carousel active={index === 0} carouselItems={carouselItems} />

          <Spacer size={[24, 90]} />
        </ContentSection>
      )}

      <ContentSection
        ref={ref => ($blocks.current[1] = ref)}
        active={index === 1}
      >
        <Spacer size={[36, 90]} />

        <Grid desk={9} deskM={10}>
          <GridItem
            desk={7}
            deskStart={2}
            deskM={6}
            deskMStart={3}
            contained={5}
            containedStart={3}
          >
            <RichText content={audioTranscript} animate={index === 1} />
          </GridItem>
        </Grid>
        <Spacer size={60} />
      </ContentSection>

      {map && (
        <ContentSection
          ref={ref => ($blocks.current[2] = ref)}
          active={index === 2}
          fillHeight
        >
          <Spacer size={24} />

          <Map active={index === 2} {...map} />

          <Spacer size={[24, 90]} />
        </ContentSection>
      )}
    </ContentMain>
  )
}

Content.propTypes = {}

export default Content
