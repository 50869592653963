import React from 'react'

const Info = ({
  width = 4,
  height = 15,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4 15"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M1.986 3.22c.7 0 1.22-.52 1.22-1.2 0-.68-.52-1.2-1.22-1.2-.68 0-1.2.52-1.2 1.2 0 .68.52 1.2 1.2 1.2ZM1.166 15h1.66V4.92h-1.66V15Z"
        fill={fill}
      />
    </svg>
  )
}

export default Info
