import React from 'react'

const Pause = ({
  width = 8,
  height = 12,
  fill = 'currentColor',
  responsive = true,
  open = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 12"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path fill={fill} d="M0 0h2v12H0z" />
      <path fill={fill} d="M6 0h2v12H6z" />
    </svg>
  )
}

export default Pause
