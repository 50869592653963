import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '@Store/'
import PropTypes from 'prop-types'
import {
  AudioPlayerMain,
  AudioPlayerWrap,
  AudioPlayerInner,
  AudioPlayerButton,
  AudioPlayerLineWrap,
  AudioPlayerLine,
  AudioPlayerLineProgress,
  AudioPlayerLineDisc,
  AudioPlayerProgress,
  AudioPlayerDuration,
} from './index.style'
import Container from '@components/Container'
import Button from '@components/Button'
import { CaptionSmall } from '@components/TextStyles'
import Play from '@svgs/Play'
import Pause from '@svgs/Pause'
import { colors } from '@styles/vars/colors.style'
import { localise } from '@utils/localise'

const AudioPlayer = ({
  podcastAudioTrack,
  podcastPlaying,
  setPodcastPlaying,
  setNextEpisodeOpen,
}) => {
  const [store] = useStore()
  const { showIntroMask, showPageMask } = store

  const audioRef = useRef(null)
  const [audioProgress, setAudioProgress] = useState(0)
  const [audioProgressFormatted, setAudioProgressFormatted] = useState(`0:00`)
  const [audioDuration, setAudioDuration] = useState(6000)
  const [audioDurationFormatted, setAudioDurationFormatted] = useState(`0:00`)

  useEffect(() => {
    if (podcastPlaying) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
  }, [podcastPlaying])

  const formatSecondsToMinSec = secs => {
    const time = new Date(secs * 1000).toISOString().substring(14, 19)

    return time[0] === '0' ? time.substring(1, 5) : time
  }

  useEffect(() => {
    const audioElement = audioRef.current

    const audioProgressed = () => {
      setAudioProgress(audioElement.currentTime)
      setAudioProgressFormatted(formatSecondsToMinSec(audioElement.currentTime))
    }

    const audioLoaded = () => {
      setAudioDuration(audioElement.duration)
      setAudioDurationFormatted(formatSecondsToMinSec(audioElement.duration))
    }

    const audioPlaybackEnded = () => {
      setAudioProgress(0)
      setAudioProgressFormatted(formatSecondsToMinSec(0))
      setPodcastPlaying(false)
      setNextEpisodeOpen(true)
    }

    audioElement.addEventListener('timeupdate', audioProgressed)
    audioElement.addEventListener('durationchange', audioLoaded)
    audioElement.addEventListener('ended', audioPlaybackEnded)

    return () => {
      audioElement &&
        audioElement.removeEventListener('timeupdate', audioProgressed)
      audioElement &&
        audioElement.removeEventListener('durationchange', audioLoaded)
      audioElement &&
        audioElement.removeEventListener('ended', audioPlaybackEnded)
    }
  }, [setPodcastPlaying, setNextEpisodeOpen])

  return (
    <AudioPlayerMain>
      <AudioPlayerWrap visible={!showIntroMask && !showPageMask}>
        <Container>
          <AudioPlayerInner>
            <AudioPlayerLineWrap>
              <AudioPlayerProgress>
                <CaptionSmall>{audioProgressFormatted}</CaptionSmall>
              </AudioPlayerProgress>
              <AudioPlayerLine>
                <AudioPlayerLineProgress
                  progress={audioProgress / audioDuration}
                />
              </AudioPlayerLine>
              <AudioPlayerLineDisc progress={audioProgress / audioDuration} />
              <AudioPlayerDuration>
                <CaptionSmall>{audioDurationFormatted}</CaptionSmall>
              </AudioPlayerDuration>
            </AudioPlayerLineWrap>
            <AudioPlayerButton>
              <Button
                onClick={() => setPodcastPlaying(!podcastPlaying)}
                iconOnly={true}
                iconRight={
                  podcastPlaying ? (
                    <Pause fill={colors.dark} />
                  ) : (
                    <Play fill={colors.dark} />
                  )
                }
                label={
                  podcastPlaying
                    ? localise('Pause Episode')
                    : localise('Play Episode')
                }
              ></Button>
            </AudioPlayerButton>
          </AudioPlayerInner>
        </Container>
        <audio src={podcastAudioTrack.file.url} ref={audioRef}></audio>
      </AudioPlayerWrap>
    </AudioPlayerMain>
  )
}

AudioPlayer.propTypes = {
  podcastAudioTrack: PropTypes.object,
  podcastPlaying: PropTypes.bool,
  setPodcastPlaying: PropTypes.func,
  setNextEpisodeOpen: PropTypes.func,
}

export default AudioPlayer
