import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Spacer from '@components/Spacer'
import {
  EpisodeMain,
  EpisodeSpacer,
  EpisodeInner,
  EpisodeAudioSpacer,
  EpisodeNext,
  EpisodeNextClose,
  EpisodeNextBackground,
  EpisodeContent,
  EpisodeColLeft,
  EpisodeColRight,
} from './index.style'
import Sidebar from '@components/Episode/Sidebar'
import Content from '@components/Episode/Content'
import AudioPlayer from '@components/Episode/AudioPlayer'
import PageTransitionLink from '@components/PageTransitionLink'
import { GatsbyImage } from 'gatsby-plugin-image'
import PlayButton from '@components/PlayButton'
import Close from '@svgs/Close'
import { useStore } from '@Store/'

const Episode = ({
  episodeNumber,
  title,
  intro,
  carouselItems,
  audioTranscript,
  podcastAudioTrack,
  map,
  sibling,
}) => {
  const [store] = useStore()
  const { showPageMask } = store

  const [contentIndex, setContentIndex] = useState(0),
    [podcastPlaying, setPodcastPlaying] = useState(false),
    [nextEpisodeOpen, setNextEpisodeOpen] = useState(false)

  const closeNextEpisode = () => {
    setNextEpisodeOpen(false)
  }

  return (
    <EpisodeMain>
      <EpisodeInner>
        <EpisodeSpacer>
          <Spacer size={[64, 118]} />
        </EpisodeSpacer>

        <EpisodeContent>
          <EpisodeColLeft>
            <Sidebar
              episodeNumber={episodeNumber}
              title={title}
              intro={intro}
              audioTranscript={audioTranscript}
              map={map}
              contentIndex={contentIndex}
              setContentIndex={setContentIndex}
              podcastPlaying={podcastPlaying}
              setPodcastPlaying={setPodcastPlaying}
            />
          </EpisodeColLeft>

          <EpisodeColRight>
            <Content
              index={contentIndex}
              carouselItems={carouselItems}
              audioTranscript={audioTranscript}
              map={map}
            />
          </EpisodeColRight>
        </EpisodeContent>

        <EpisodeAudioSpacer />

        {sibling && (
          <EpisodeNext visible={nextEpisodeOpen && !showPageMask}>
            <EpisodeNextClose onClick={closeNextEpisode}>
              <Close />
            </EpisodeNextClose>
            <PageTransitionLink to={`/${sibling.slug}`}>
              <EpisodeNextBackground>
                <GatsbyImage
                  image={sibling.episodeThumbnail.gatsbyImageData}
                  alt={sibling.episodeThumbnail.description}
                />
              </EpisodeNextBackground>
              <PlayButton label="Next Episode" />
            </PageTransitionLink>
          </EpisodeNext>
        )}
      </EpisodeInner>

      {podcastAudioTrack && (
        <AudioPlayer
          podcastAudioTrack={podcastAudioTrack}
          podcastPlaying={podcastPlaying}
          setPodcastPlaying={setPodcastPlaying}
          setNextEpisodeOpen={setNextEpisodeOpen}
        />
      )}
    </EpisodeMain>
  )
}

Episode.propTypes = {
  episodeNumber: PropTypes.number,
  title: PropTypes.string,
  intro: PropTypes.object,
  audioTranscript: PropTypes.object,
  podcastAudioTrack: PropTypes.object,
  podcastDuration: PropTypes.string,
}

export default Episode
