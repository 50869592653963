import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from '@Store/'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { MapMain, MapInner, MapImageWrap, MapImage } from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateImage from '@components/animation/AnimateImage'

const Map = ({ active, gatsbyImageData, description }) => {
  const [store] = useStore()
  const { showPageMask, showIntroMask } = store

  return (
    <MapMain>
      <MapInner>
        <Grid desk={9} deskM={10}>
          <GridItem desk={6} deskStart={2}>
            <MapImageWrap visible={!showPageMask && !showIntroMask && active}>
              <MapImage>
                <AnimateImage
                  animate={!showPageMask && !showIntroMask && active}
                  parallax={1}
                >
                  <GatsbyImage image={gatsbyImageData} alt={description} />
                </AnimateImage>
              </MapImage>
            </MapImageWrap>
          </GridItem>
        </Grid>
      </MapInner>
    </MapMain>
  )
}

Map.propTypes = {
  active: PropTypes.bool,
  gatsbyImageData: PropTypes.object,
  description: PropTypes.string,
}

export default Map
