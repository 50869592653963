import React from 'react'
import {
  showOverlayNav,
  showPageMask,
  showMenuMask,
  updatePathname,
  useStore,
} from '@Store'
import Spacer from '@components/Spacer'
import {
  CaptionSmall,
  CtaSmall,
  Heading4,
  TextBodySmall,
} from '@components/TextStyles'
import { colors } from '@styles/vars/colors.style'
import {
  SidebarMain,
  SidebarTop,
  SidebarIntro,
  SidebarSelect,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuLink,
} from './index.style'
import Select from '@components/Select'
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import { animation } from '@styles/vars/animation.style'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { localise } from '@utils/localise'

const Sidebar = ({
  episodeNumber,
  title,
  intro,
  audioTranscript,
  map,
  contentIndex,
  setContentIndex,
}) => {
  const [, dispatch] = useStore()

  const showPodcastLinks = useTriggerTransition()

  const handleSelect = (e, value) => {
    if (value) {
      e.preventDefault()
    } else {
      const select = e.target
      value = select.value
    }

    if (value === '1') {
      setContentIndex(0)
    } else if (value === '2') {
      setContentIndex(1)
    } else if (value === '3') {
      setContentIndex(2)
    } else if (value === '4') {
      updatePathname(dispatch, '/about')
      showPageMask(dispatch)
      showPodcastLinks({
        to: '/about?podcast',
        exit: {
          delay: animation.maskShowDuration,
          length: 0,
          zIndex: 2,
        },
        entry: {
          delay: 0,
          length: animation.maskHideDuration,
          zIndex: 1,
        },
      })
    } else if (value === '5') {
      showMenuMask(dispatch)
      showOverlayNav(dispatch)
    }
  }
  return (
    <SidebarMain>
      <SidebarTop>
        <Spacer size={[32, 40]} />
        <AnimateSlideIn>
          <CaptionSmall>
            {localise('Episode')} {episodeNumber}
          </CaptionSmall>
        </AnimateSlideIn>
        <Spacer size={[6, 12]} />
        <Heading4 color={colors.pink2}>
          <AnimateSplitText delay={animation.lineDuration}>
            {title}
          </AnimateSplitText>
        </Heading4>
        <SidebarIntro>
          <Spacer size={18} />
          <TextBodySmall maxWidth={35}>
            <AnimateSplitText delay={animation.lineDuration * 2}>
              {intro.intro}
            </AnimateSplitText>
          </TextBodySmall>
        </SidebarIntro>
      </SidebarTop>
      <AnimateSlideIn delay={0.6}>
        <SidebarSelect>
          <Select onChange={handleSelect}>
            <option value={1}>{localise('Listen to episode')}</option>
            {audioTranscript && (
              <option value={2}>{localise('Read transcription')}</option>
            )}
            {map && <option value={3}>{localise('View map location')}</option>}
            <option value={4}>{localise('Listen to podcast')}</option>
            <option value={5}>{localise('View all episodes')}</option>
          </Select>
        </SidebarSelect>
      </AnimateSlideIn>
      <SidebarMenu>
        <SidebarMenuItem active={contentIndex === 0}>
          <CtaSmall>
            <AnimateSlideIn delay={0.6} rootMargin={`0%`}>
              <SidebarMenuLink href="" onClick={e => handleSelect(e, '1')}>
                {localise('Listen to episode')}
              </SidebarMenuLink>
            </AnimateSlideIn>
          </CtaSmall>
        </SidebarMenuItem>
        {audioTranscript && (
          <SidebarMenuItem active={contentIndex === 1}>
            <CtaSmall>
              <AnimateSlideIn
                delay={0.6 + animation.lineDuration}
                rootMargin={`0%`}
              >
                <SidebarMenuLink href="" onClick={e => handleSelect(e, '2')}>
                  {localise('Read transcription')}
                </SidebarMenuLink>
              </AnimateSlideIn>
            </CtaSmall>
          </SidebarMenuItem>
        )}
        {map && (
          <SidebarMenuItem active={contentIndex === 2}>
            <CtaSmall>
              <AnimateSlideIn
                delay={0.6 + animation.lineDuration * (audioTranscript ? 2 : 1)}
                rootMargin={`0%`}
              >
                <SidebarMenuLink href="" onClick={e => handleSelect(e, '3')}>
                  {localise('View map location')}
                </SidebarMenuLink>
              </AnimateSlideIn>
            </CtaSmall>
          </SidebarMenuItem>
        )}
        <SidebarMenuItem>
          <CtaSmall>
            <AnimateSlideIn
              delay={
                0.6 +
                animation.lineDuration *
                  (audioTranscript && map ? 3 : audioTranscript || map ? 2 : 1)
              }
              rootMargin={`0%`}
            >
              <SidebarMenuLink href="" onClick={e => handleSelect(e, '4')}>
                {localise('Listen to podcast')}
              </SidebarMenuLink>
            </AnimateSlideIn>
          </CtaSmall>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <CtaSmall>
            <AnimateSlideIn
              delay={
                0.6 +
                animation.lineDuration *
                  (audioTranscript && map ? 4 : audioTranscript || map ? 3 : 2)
              }
              rootMargin={`0%`}
            >
              <SidebarMenuLink href="" onClick={e => handleSelect(e, '5')}>
                {localise('View all episodes')}
              </SidebarMenuLink>
            </AnimateSlideIn>
          </CtaSmall>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarMain>
  )
}

Sidebar.propTypes = {}

export default Sidebar
