import React from 'react'

const SelectArrow = ({
  width = 12,
  height = 8,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 8"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M11.3135 2.0104L9.89926 0.596191L5.65662 4.83883L1.41398 0.596191L-0.000230851 2.0104L5.65662 7.66726L11.3135 2.0104Z"
        fill={fill}
      />
    </svg>
  )
}

export default SelectArrow
