import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useStore, setHeaderDark } from '@Store/'
import Seo from '@components/Seo'
import Episode from '@components/Episode'
import gsap from 'gsap'
import { animation } from '@styles/vars/animation.style'


const EpisodePage = ({ data }) => {
  const [, dispatch] = useStore()

  useEffect(() => {
    gsap.delayedCall(animation.maskShowDuration, () => setHeaderDark(dispatch))
  }, [dispatch])

  const { seo, slug } = data.contentfulEpisode

  return (
    <>
      <Seo data={seo} slug={slug} />
      <Episode {...data.contentfulEpisode} sibling={data.sibling} />
    </>
  )
}

export default EpisodePage

export const pageQuery = graphql`
  query EpisodeQuery(
    $slug: String!
    $hasSibling: Boolean!
    $siblingSlug: String!
  ) {
    contentfulEpisode(slug: { eq: $slug }) {
      seo {
        ...SEO
      }
      slug
      episodeNumber
      title
      intro {
        intro
      }
      carouselItems {
        caption {
          raw
        }
        credit {
          raw
        }
        image {
          gatsbyImageData
          description
        }
      }
      audioTranscript {
        raw
      }
      podcastAudioTrack {
        file {
          url
          contentType
        }
      }
      map {
        gatsbyImageData(quality: 90)
        description
      }
    }
    sibling: contentfulEpisode(slug: { eq: $siblingSlug })
      @include(if: $hasSibling) {
      slug
      title
      episodeThumbnail {
        gatsbyImageData
        description
      }
    }
  }
`
