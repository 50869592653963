import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from '@Store/'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import {
  CarouselMain,
  CarouselInner,
  CarouselImages,
  CarouselImage,
  CarouselDetailGridItem,
  CarouselDetails,
  CarouselDetail,
  CarouselControls,
  CarouselCounter,
  CarouselPagination,
  CarouselDots,
  CarouselDot,
  CarouselInfo,
} from './index.style'
import { CaptionSmall, TextBodySmall } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import { GatsbyImage } from 'gatsby-plugin-image'
import RichText from '@components/RichText'
import Button from '@components/Button'
import { colors } from '@styles/vars/colors.style'
import IconArrowRight from '@svgs/IconArrowRight'
import Info from '@svgs/Info'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import { useSwipeable } from 'react-swipeable'
import Close from '@svgs/Close'
import { localise } from '@utils/localise'

const Carousel = ({ active, carouselItems }) => {
  const [store] = useStore()
  const { showPageMask, showIntroMask } = store

  const [activeIndex, setActiveIndex] = useState(0)
  const [mobileCaptionsOpen, setMobileCaptionsOpen] = useState(false)

  const previous = () => {
    setActiveIndex(activeIndex > 0 ? activeIndex - 1 : activeIndex)
  }

  const next = () => {
    setActiveIndex(
      activeIndex < carouselItems.length - 1 ? activeIndex + 1 : activeIndex
    )
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      next()
    },
    onSwipedRight: () => {
      previous()
    },
    trackMouse: true,
  })

  useEffect(() => {
    setMobileCaptionsOpen(false)
  }, [activeIndex])

  return (
    <CarouselMain {...handlers}>
      <CarouselInner>
        <Grid desk={9} deskM={10}>
          <GridItem desk={5} deskStart={2} deskM={6} deskMStart={2}>
            <CarouselImages visible={!showPageMask && !showIntroMask && active}>
              {React.Children.toArray(
                carouselItems.map((item, itemIndex) => {
                  return (
                    <CarouselImage
                      active={activeIndex <= itemIndex && active}
                      current={activeIndex === itemIndex && active}
                      zPos={activeIndex - itemIndex}
                      zIndex={1 - itemIndex}
                    >
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        alt={item.image.description}
                      />
                    </CarouselImage>
                  )
                })
              )}
            </CarouselImages>
          </GridItem>

          <CarouselDetailGridItem
            desk={2}
            deskStart={8}
            deskAlign={`flex-end`}
            deskM={2}
            deskMStart={9}
          >
            <CarouselDetails mobileActive={mobileCaptionsOpen}>
              {React.Children.toArray(
                carouselItems.map((item, itemIndex) => {
                  return (
                    <CarouselDetail
                      active={activeIndex === itemIndex && active}
                    >
                      <RichText
                        animate={activeIndex === itemIndex && active}
                        delay={0.3}
                        content={item.caption}
                        paragraphSize={`small`}
                      />
                      {item.credit && (
                        <>
                          <Spacer size={[10, 20]} />
                          <AnimateSplitText
                            animate={activeIndex === itemIndex && active}
                            delay={0.4}
                          >
                            <CaptionSmall>
                              {localise('Photo Credit')}
                            </CaptionSmall>
                          </AnimateSplitText>
                          <Spacer size={2} />
                          <RichText
                            animate={activeIndex === itemIndex && active}
                            delay={0.5}
                            content={item.credit}
                            paragraphSize={`small`}
                          />
                        </>
                      )}
                    </CarouselDetail>
                  )
                })
              )}
            </CarouselDetails>

            <AnimateSlideIn animate={active} delay={0.6} rootMargin={'0px'}>
              <CarouselControls>
                <Button
                  label={localise('Previous')}
                  iconRight={<IconArrowRight />}
                  iconOnly={true}
                  flip={true}
                  color={colors.dark}
                  onClick={previous}
                  disabled={activeIndex === 0}
                />

                <CarouselCounter>
                  <TextBodySmall>
                    {activeIndex + 1}/{carouselItems.length}
                  </TextBodySmall>
                </CarouselCounter>

                <Button
                  label={localise('Next')}
                  iconRight={<IconArrowRight />}
                  iconOnly={true}
                  color={colors.dark}
                  onClick={next}
                  disabled={activeIndex === carouselItems.length - 1}
                />
              </CarouselControls>
            </AnimateSlideIn>
          </CarouselDetailGridItem>
        </Grid>
      </CarouselInner>

      <CarouselPagination>
        <Spacer size={30} />

        <AnimateSlideIn animate={active} rootMargin={`0%`} delay={0.6}>
          <CarouselDots>
            {React.Children.toArray(
              carouselItems.map((item, itemIndex) => {
                return (
                  <CarouselDot
                    active={
                      activeIndex === itemIndex &&
                      active &&
                      !showPageMask &&
                      !showIntroMask
                    }
                  />
                )
              })
            )}
          </CarouselDots>
        </AnimateSlideIn>

        <AnimateSlideIn animate={active} rootMargin={`0%`} delay={0.65}>
          <CarouselInfo>
            <Button
              iconRight={mobileCaptionsOpen ? <Close /> : <Info />}
              iconOnly={true}
              onClick={() => setMobileCaptionsOpen(!mobileCaptionsOpen)}
            />
          </CarouselInfo>
        </AnimateSlideIn>
        <Spacer size={30} />
      </CarouselPagination>
    </CarouselMain>
  )
}

Carousel.propTypes = {
  active: PropTypes.bool,
  carouselItems: PropTypes.array,
}

export default Carousel
